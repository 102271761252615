/***
 * global_add.js
 * 2017年リニューアルで、global.jsの編集が実質不可能になっていたので、追加する形をとりました。
 * 全ファイルの、最後に読み込まれる /common/user_sp_script/global_add.jsを生成しています。
 */

$(function(){

  // アプリヘッダーのクリック処理再定義
  $(".appHeader .btn").off("click");
  
  // アプリヘッダーを消す
  $(".appHeader .btn").click(function () {
    $(this).parents(".appHeader").hide();
    $("#spHeader").removeClass("be_hide");
    $("#globalhideMenu").removeClass("md_menu--app");

    // 今後表示しないように、クッキーに情報を保存
    var expire = new Date();
    expire.setTime(expire.getTime() + 1000 * 3600 * 336);
    document.cookie = 'APPBANNERDELETE=1;path = /; expires=' + expire.toUTCString() + '; ';
  });

  //企業名公開訴求モーダル
  $('.js_company_publication_closeBtn').click(function() {
    $(".md_modalIdConnect").hide();
    $("#js_modalScreen").hide();
    parent.location.reload();
  });

  $('.js_company_publication_txtLink').click(function() {
    $(".md_modalIdConnect").hide();
    $("#js_modalScreen").hide();
    parent.location.reload();
  });

  $('.js_company_publication_infoDesc').click(function() {
    $.ajax({
      type: 'POST',
      url: $('input[name=company_publication_api_url]').val(),
      data: { E13CT: $("#token_for_ajax").val(), },
      cache: false,
      async: false,
      dataType: 'json'
    });
    $(".md_modalIdConnect").hide();
    $("#js_modalScreen").hide();
    parent.location.reload();
  });

})